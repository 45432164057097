import React, { useState, useEffect } from "react";
import { sectionWrapper } from "../hoc";
import { gql, GraphQLClient } from "graphql-request";
import { HiArrowRight } from "react-icons/hi";

const MY_QUERY = gql`
  query MyQuery {
    portfolios(where: { selectPortfolioCategory_in: Apps }) {
      id
      link
      selectPortfolioCategory
      portfolioTitle
      portfolioAssetImage {
        url
      }
      portfolioDescription {
        html
      }
    }
  }
`;

function PortfolioAppGallery() {
  const [data, setData] = useState(null);
  const [showMore, setShowMore] = useState(false);

  const [lightboxOpen, setLightboxOpen] = useState(false);

  useEffect(() => {
    const client = new GraphQLClient(
      "https://api-us-east-1-shared-usea1-02.hygraph.com/v2/clox7w0ka0bkq01uq5003fqcm/master"
    );
    const fetchData = async () => {
      const data = await client.request(MY_QUERY);
      setData(data);
    };
    fetchData();
  }, []);

  if (!data) return <div>Loading...</div>;

  const portfolioSlice = showMore
    ? data.portfolios
    : data.portfolios.slice(0, 3);

  const handleLoadMore = () => {
    setShowMore(true);
  };

  return (
    <div class="gap-x-4">
      {portfolioSlice.map((post) => (
        <div className="w-full">
          <div key={post.id} className="flex flex-col items-center w-full mb-10 2xl:flex-row xl:flex-row lg:flex-row md:flex-row ">
            <div className="2xl:w-[50%] xl:w-[50%] lg:w-[50%] md:w-[50%] w-full">
              {/**first column for single image */}
           <img src={post.portfolioAssetImage.url} className="w-full h-full" />
              </div>
              <div className="2xl:w-[50%] xl:w-[50%] lg:w-[50%] md:w-[50%] content-center items-center justify-center w-full">
                {/** second column title and desciptions */}
                      <p className="p-3 font-bold text-[36px] text-white">
                      <a href={post.link}>{post.portfolioTitle}</a>
                    </p>
                                <div
                          className=" text-[15px] bg-[#0b081c] rounded-md p-3 ml-auto mr-auto"
                          dangerouslySetInnerHTML={{
                            __html: post.portfolioDescription.html,
                          }}
                        />

<p class="text-white flex flex-row justify-start gap-2 p-3">
              <a href={post.link} class="relative group">
                <span class="group-hover:text-gray-300 ">
                  More Details
                  <span class="absolute top-1 transform translate-x-2 transition-transform duration-300 group-hover:translate-x-4">
                    <HiArrowRight />
                  </span>
                </span>
              </a>
            </p>

                </div>

           

          

            

         
          </div>








        </div>
      ))}
      {!showMore && (
        <div className="w-full mt-4 text-center">
          <button
            onClick={handleLoadMore}
            className="px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-700"
          >
            Load More
          </button>
        </div>
      )}
    </div>
  );
}

export default sectionWrapper(PortfolioAppGallery);
