import { motion } from "framer-motion"
import { useState,useRef } from "react"
import { styles } from "../styles"
import axios from "axios";
import { sectionWrapper } from "../hoc"
import { slideIn } from "../utils/motion"
import { contactPage, emailIconjn } from "../assets"
import Lottie from "lottie-react";
import emailjs from '@emailjs/browser';





const Contact = () => {

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_tevtjug', 'template_4ysk27t', form.current, '9wRe4B2w_RrusdCmg')
      .then((result) => {
          console.log(result.text);
          window.open("/thank-you", "_blank");
      }, (error) => {
          console.log(error.text);
      });
  };


  return (
   <div className="flex flex-col-reverse gap-10 overflow-hidden xl:mt-12 xl:flex-row">
    <div variants={slideIn('left','tween',0,2,1)} className="flex-[0.78] bg-black-100 p-8 rounded-2xl">
      <p className={styles.sectionSubtext}>Get in Touch</p>
      <h3 className={styles.sectionHeadText}>Contact.</h3>
      <form  ref={form} onSubmit={sendEmail} className="flex flex-col gap-8 mt-12">
        <label className="flex flex-col">
          <span>Your Name</span>
          <input
          type='text'
          id="user_name"
          name="user_name"
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="px-6 py-4 font-medium text-white border-none rounded-lg outline-none bg-tertiary placeholder:text-tertiary"/>
        </label>

        { /** email */}

        <label className="flex flex-col">
          <span>Your Email</span>
          <input
          id="user_email"
          type='text'
          name="user_email"
          placeholder="info@example.com"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="px-6 py-4 font-medium text-white border-none rounded-lg outline-none bg-tertiary placeholder:text-tertiary"/>
        </label>
        
        <label className="flex flex-col">
          <span>Your Phone No. </span>
          <input
          id="user_phone"
          type='tel'
          name="user_phone"
          placeholder="xxx xxxxx"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          className="px-6 py-4 font-medium text-white border-none rounded-lg outline-none bg-tertiary placeholder:text-tertiary"/>
        </label>

        { /** message */}

        <label className="flex flex-col">
          <span>Message</span>
          <textarea
          id="message"
          name="message"
          rows="4"
          placeholder="What's your query?"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          className="px-6 py-4 font-medium text-white border-none rounded-lg outline-none bg-tertiary placeholder:text-tertiary"/>
        </label>

        <button
        type="submit"
        className="px-8 py-3 font-bold border-none shadow-md outline-none bg-tertiary w-fit shadow-primary rounded-xl"        
        >Submit</button>


      </form>
    </div>
    <div variants={slideIn('left','tween',0,2,1)} className="xl:flex-1 xl:h-auto md:h-[550px] h-[350px]">
    <Lottie animationData={contactPage}  />
      
      

    </div>

   </div>
  )
}

export default sectionWrapper(Contact,"")