import Contact from '../components/Contact'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import React, { useEffect } from 'react'
import { gql, GraphQLClient } from "graphql-request";
import { useState } from 'react';
import { Helmet } from 'react-helmet';







const MY_QUERY = gql`
query MyQuery {
    privacyPolicies {
      descriptions {
        html
      }
    }
  }
  
  
`;

function PrivacyPolicy() {
    const [data, setData] = useState(null);

    useEffect(() => {
      const client = new GraphQLClient(
        "https://api-us-east-1-shared-usea1-02.hygraph.com/v2/clox7w0ka0bkq01uq5003fqcm/master"
      );
      const fetchData = async () => {
        const data = await client.request(MY_QUERY);
        setData(data);
      };
      fetchData();
    }, []);
  
    if (!data) return <div>Loading...</div>;
    return (
      <div className='relative z-0 bg-primary'>
        <Helmet>
<title>Privacy Policy - The Designsinc.</title>
</Helmet>
      <div className=''>
        <Navbar />
        <div className='left-auto right-auto items-center justify-center px-48'>
          {/** Contact Section from components comes through */}
          <div className='relative z-0 pt-32 pb-10 '>
          {data.privacyPolicies.map((post) => (
            <div className="" dangerouslySetInnerHTML={{__html:post.descriptions.html}} />

          ))}
         
        </div>
          </div>
      </div>
     
      <Footer />
  
    </div>
    )
  }
  

export default PrivacyPolicy