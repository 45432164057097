import React from "react";

import { motion } from "framer-motion";

import { styles } from "../styles";
import { services } from "../constant";
import { fadeIn, textVariant, slideIn } from "../utils/motion";
import { sectionWrapper } from "../hoc";
import { aiwebsitemac } from "../assets";

const ServiceCard = ({ index, title, icon }) => {
  return (
    <div className="xs:w-[250px] w-full ">
      <div className="w-full green-pink-gradient p-[1px] rounded-[20px] shadow-card">
        <div
          options={{
            max: 45,
            scale: 1,
            speed: 450,
          }}
          className="bg-tertiary rounded-[20px] py-5 px-12 min-h-[280px] flex justify-evenly items-center flex-col"
        >
          <img src={icon} alt={title} className="object-contain w-16 h-16" />
          <h3 className="text-white text-[20px] font-bold text-center">
            {title}
          </h3>
        </div>
      </div>
    </div>
  );
};

function WebDesignHero() {
  return (
    <>
      <div className="flex flex-col items-center justify-end pt-20 2xl:flex-row xl:flex-row lg:flex-row md:flex-row gap-x-28 ">
        <div className="w-full 2xl:w-1/2 xl:2xl:w-1/2 lg:2xl:w-1/2 md:2xl:w-1/2 ">
          <div>
            <p className="sm:text-[18px] text-[14px] text-secondary uppercase tracking-wider">
              DESIGN IS POWERFUL
            </p>
            <h2 className="text-white font-black md:text-[40px] sm:text-[40px] xs:text-[20px] text-[30px]">
              Beautiful web design that converts visitors into customers
            </h2>
          </div>

          <p className="mt-4 text-secondary text-[17px] max-w-3xl leading-[30px]">
            TheTechnofied.com is a dynamic web design agency that is passionate
            about creating exceptional digital experiences. We believe that
            every website should be both aesthetically pleasing and functional,
            seamlessly blending design and technology. With a focus on
            innovation and collaboration, our team works tirelessly to deliver
            custom web solutions that meet the unique needs of each of our
            clients.
          </p>
        </div>
        <div className="w-full 2xl:w-1/2 xl:2xl:w-1/2 lg:2xl:w-1/2 md:2xl:w-1/2">
          <div>
            <img src={aiwebsitemac} className="w-500 h-500 " alt="img" />
          </div>
        </div>
      </div>

      <div className="flex-col gap-10 mt-20 2xl:flex 2xl:flex-row xl:flex xl:flex-row md:flex md:flex-row">
        {services.map((service, index) => (
          <ServiceCard key={service.title} index={index} {...service} />
        ))}
      </div>
    </>
  );
}

export default sectionWrapper(WebDesignHero);
