import React from "react";

import { motion } from "framer-motion";

import { styles } from "../styles";
import { services } from "../constant";
import { fadeIn, textVariant, slideIn } from "../utils/motion";
import { sectionWrapper } from "../hoc";
import { circleaboutimage } from "../assets";


const ServiceCard = ({ index, title, icon }) => {
  return (
    <div className="xs:w-[250px] w-full ">
      <div
        
        className="w-full green-pink-gradient p-[1px] rounded-[20px] shadow-card"
      >
        <div
          options={{
            max: 45,
            scale: 1,
            speed: 450,
          }}
          className="bg-tertiary rounded-[20px] py-5 px-12 min-h-[280px] flex justify-evenly items-center flex-col"
        >
          <img src={icon} alt={title} className="object-contain w-16 h-16" />
          <h3 className="text-white text-[20px] font-bold text-center">
            {title}
          </h3>
        </div>
      </div>
    </div>
  );
};

function PackagesHero() {
  return (
    <>
      <div className="flex flex-row items-center justify-end pt-10 gap-x-28 ">
        <div>
          <div >
            <p className="sm:text-[18px] text-[14px] text-secondary uppercase tracking-wider">
              OUR PACKAGES
            </p>
            <h2 className="text-white font-black md:text-[40px] sm:text-[40px] xs:text-[40px] text-[30px]">
              Find the perfect package for you
            </h2>
          </div>

          <p
            className="mt-4 text-secondary text-[17px] max-w-3xl leading-[30px]"
            
          >
            Experience exceptional design with TheTechnofied.com - choose from our
            customized pricing packages to suit your business needs. Our
            affordable rates ensure quality service without breaking the bank.
            Get in touch with us today to learn more.
          </p>
        </div>
        <div className="w-1/2">
          <div >
            <img
              src={circleaboutimage}
              className="object-contain w-100 h-100"
              alt="img"
            />
          </div>
        </div>
      </div>

     
    </>
  );
}

export default sectionWrapper(PackagesHero);
