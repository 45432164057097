import Carousel from '../components/Carousel'
import Contact from '../components/Contact'
import Feedbacks from '../components/Feedbacks'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import IdeaSectionAbout from '../section/IdeaSectionAbout'
import SocialMediaHero from '../section/SocialMediaHero'
import React from 'react'
import { Helmet } from 'react-helmet';


function SocialMedia() {
  return (
    <div className="relative z-0 bg-[#060816]">
        <Helmet>
<title>Social Media Marketing - The Designsinc.</title>
</Helmet>

    <div>
      <Navbar />
      <div>
        {/** About Section */}
       <SocialMediaHero />
        {/**Slider logos company */}
        <Carousel />

        {/**idea section and btns */}
        <IdeaSectionAbout />

        {/** Testimonials */}
        <div className="-mt-36">
        <Feedbacks  />
        </div>
        {/** contact section */}
        <div className='relative z-0'>
      <Contact />
     
    </div>
      </div>
    </div>

    <Footer />
  </div>
  )
}

export default SocialMedia