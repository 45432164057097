import React from "react";

import { motion } from "framer-motion";

import { styles } from "../styles";
import { services } from "../constant";
import { fadeIn, textVariant, slideIn } from "../utils/motion";
import { sectionWrapper } from "../hoc";
import { aiimg, HeroLogo } from "../assets";

const ServiceCard = ({ index, title, icon }) => {
  return (
    <div className="xs:w-[250px] w-full ">
      <div className="w-full green-pink-gradient p-[1px] rounded-[20px] shadow-card">
        <div
          options={{
            max: 45,
            scale: 1,
            speed: 450,
          }}
          className="bg-tertiary rounded-[20px] py-5 px-12 min-h-[280px] flex justify-evenly items-center flex-col"
        >
          <img src={icon} alt={title} className="object-contain w-16 h-16" />
          <h3 className="text-white text-[20px] font-bold text-center">
            {title}
          </h3>
        </div>
      </div>
    </div>
  );
};

function ModerLogoHero() {
  return (
    <>
      <div className="flex flex-col items-center justify-end pt-20 2xl:flex-row xl:flex-row lg:flex-row md:flex-row gap-x-28 ">
        <div className="w-full 2xl:w-1/2 xl:2xl:w-1/2 lg:2xl:w-1/2 md:2xl:w-1/2 ">
          <div>
            <p className="sm:text-[18px] text-[14px] text-secondary uppercase tracking-wider">
              DESIGN IS POWERFUL
            </p>
            <h2 className="text-white font-black md:text-[40px] sm:text-[40px] xs:text-[20px] text-[30px]">
              Achieve Your Dream Logo Design with Ease.
            </h2>
          </div>

          <p className="mt-4 text-secondary text-[17px] max-w-3xl leading-[30px]">
            Our company is dedicated to providing businesses with innovative
            logo design solutions that are easy to achieve. We understand the
            importance of a logo that accurately reflects your brand identity,
            and our streamlined logo design process ensures that you achieve
            just that. Our team of expert designers uses the latest technology
            and software to create modern logo designs that stand out and make a
            lasting impression.
          </p>
        </div>
        <div className="w-full 2xl:w-1/2 xl:2xl:w-1/2 lg:2xl:w-1/2 md:2xl:w-1/2 ">
          <div>
            <img src={HeroLogo} className="max-w-sm w-300 h-200" alt="img" />
          </div>
        </div>
      </div>

      <div className="flex-col gap-10 mt-20 2xl:flex 2xl:flex-row xl:flex xl:flex-row md:flex md:flex-row">
        {services.map((service, index) => (
          <ServiceCard key={service.title} index={index} {...service} />
        ))}
      </div>
    </>
  );
}

export default sectionWrapper(ModerLogoHero);
