import Carousel from '../components/Carousel'
import Contact from '../components/Contact'
import Feedbacks from '../components/Feedbacks'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import IdeaSectionAbout from '../section/IdeaSectionAbout'
import MobileAppsHero from '../section/MobileAppsHero'
import React from 'react'
import PortfolioAppGallery from '../components/PortfolioAppGallery'
import AppGalleries from '../components/AppGalleries'
import { Helmet } from 'react-helmet';


function MobileApps() {
  return (
    <div className="relative z-0 bg-[#060816]">
      <Helmet>
        <title>Mobile App - The Designsinc.</title>
      </Helmet>
    <div>
      <Navbar />
      <div>
        {/** About Section */}
       <MobileAppsHero />
        {/**Slider logos company */}
        <Carousel />

        {/**idea section and btns */}
        <IdeaSectionAbout />

        {/**Mobile Apps Show Case */}
        <PortfolioAppGallery />

        {/** For Apps Galleries */}
        <h2 className="text-center p-3 font-bold text-[36px] text-white">App UI/UX Gallery</h2>
        <AppGalleries />

        {/** Testimonials */}
        <div className="">
        <Feedbacks  />
        </div>
        {/** contact section */}
        <div className='relative z-0'>
      <Contact />
    
    </div>
      </div>
    </div>

    <Footer />
  </div>
  )
}

export default MobileApps