import React from "react";


import Carousel from "../components/Carousel";
import Navbar from "../components/Navbar";


import AboutUSPage from "../section/AboutUSPage";
import IdeaSectionAbout from "../section/IdeaSectionAbout";
import Feedbacks from "../components/Feedbacks";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import { Helmet } from 'react-helmet';

function AboutUs() {
  return (
    <div className="relative z-0 bg-[#060816]">
       <Helmet>
        <title>About Us - The Designsinc.</title>
      </Helmet>
      <div>
        <Navbar />
        <div>
          {/** About Section */}
          <div className="mt-10">
          <AboutUSPage  />
          </div>
          {/**Slider logos company */}
          <Carousel  />

          {/**idea section and btns */}
          <IdeaSectionAbout />

          {/** Testimonials */}
          <div className="">
          <Feedbacks  />
          </div>
          {/** contact section */}

          
          <div className='relative z-0'>
        <Contact />
       
      </div>
        </div>
      </div>

     

      <Footer />
    </div>
  );
}

export default AboutUs;
